import React from 'react';
import accountant from '../img/professions/noun_accountant_2588277.svg'
import barber from '../img/professions/noun_Barber_2588287.svg'
import butcher from '../img/professions/noun_Butcher_2588275.svg'
import carpenter from '../img/professions/noun_Carpenter_2588289.svg'
import chef from '../img/professions/noun_chef_2588303.svg'
import electrician from '../img/professions/noun_electrician_2588276.svg'
import farmer from '../img/professions/noun_Farmer_2588273.svg'
import fisherman from '../img/professions/noun_Fisherman_2588302.svg'
import designer from '../img/professions/noun_Graphic Designer_2588293.svg'
import { Link } from "gatsby"

export default function QuoteForm (props) {

    return (
        <div id="search_heading">
            <h2>Choose Your Profession or Industry</h2>
            <div id="search_box">
                <form>
                    <input type="select" value="Search"></input>
                    <input type="button" value="Submit"></input>
                </form>
            </div>

            <div id="coverage_listings">
                <div className="section">
                    <div className="col grid_1_of_4"><Link to="/"><div className ="profession-icon"><img src={accountant} alt="accountant icon"/></div><h4>Accountants</h4><p>Sapere aude. Alea iacta est. Carpe vinum. Ad astra per aspera.</p></Link></div>
                    <div className="col grid_1_of_4"><Link to="/"><div className ="profession-icon"><img src={barber} alt="barber icon"/></div><h4>Barbers</h4><p>Sapere aude. Alea iacta est. Carpe vinum. Ad astra per aspera.</p></Link></div>
                    <div className="col grid_1_of_4"><Link to="/"><div className ="profession-icon"><img src={butcher} alt="butcher icon"/></div><h4>Butchers</h4><p>Sapere aude. Alea iacta est. Carpe vinum. Ad astra per aspera.</p></Link></div>
                    <div className="col grid_1_of_4"><Link to="/"><div className ="profession-icon"><img src={carpenter} alt="carpenter icon"/></div><h4>Carpenters</h4><p>Sapere aude. Alea iacta est. Carpe vinum. Ad astra per aspera.</p></Link></div>
                    <div className="col grid_1_of_4"><Link to="/"><div className ="profession-icon"><img src={chef} alt="chef icon"/></div><h4>Chefs</h4><p>Sapere aude. Alea iacta est. Carpe vinum. Ad astra per aspera.</p></Link></div>
                    <div className="col grid_1_of_4"><Link to="/"><div className ="profession-icon"><img src={designer} alt="designer icon"/></div><h4>Designer</h4><p>Sapere aude. Alea iacta est. Carpe vinum. Ad astra per aspera.</p></Link></div>
                    <div className="col grid_1_of_4"><Link to="/"><div className ="profession-icon"><img src={electrician} alt="electrician icon"/></div><h4>Electricians</h4><p>Sapere aude. Alea iacta est. Carpe vinum. Ad astra per aspera.</p></Link></div>
                    <div className="col grid_1_of_4"><Link to="/"><div className ="profession-icon"><img src={farmer} alt="farmer icon"/></div><h4>Farmer</h4><p>Sapere aude. Alea iacta est. Carpe vinum. Ad astra per aspera.</p></Link></div>
                    <div className="col grid_1_of_4"><Link to="/"><div className ="profession-icon"><img src={fisherman} alt="fisherman icon"/></div><h4>Fisherman</h4><p>Sapere aude. Alea iacta est. Carpe vinum. Ad astra per aspera.</p></Link></div>
                    <div className="col grid_1_of_4"><Link to="/"><h4>More...</h4></Link></div>
                </div>
            </div>
        </div>
    )
}