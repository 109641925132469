import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Service from '../components/Service';
import  "../styles/321global.css"
import  "../styles/searchcoverage.css"
import SearchCoverage from "../components/SearchCoverage";
import "../styles/top-nav.css"
import TopNav from '../components/business-coverage/TopNav';

const QuotePage = (props) => {


    return (
        <div id="search_coverage">
            <TopNav />
            <Header />
            <SearchCoverage />
            <Service />
            <Footer />
        </div>


     );
}

export default QuotePage;
